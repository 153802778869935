import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXButton from "./../../../../../Components/Buttons/BMXButton/BMXButton";
import BMXUserView from "./../../../../../Components/BMXUserView/BMXUserView";
import { Icon } from "@mui/material";
import { show } from "redux-modal";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import "./OperationLockFormStepOne.scss";
const OperationLockFormStepOne = (props) => {
  const { t } = useTranslation();
  const { values, form } = props;
  const [departmentsSelect, setDepartments] = useState([]);
  const [reasonSelect, setReasonSelect] = useState([]);
  const [workers, setWorkerSelect] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userSession.user);
  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    const fetchReason = GlobalAuthFetch({
      path: "/dictionary/reason/fieldName",
    });
    const fetchWorker = GlobalAuthFetch({ path: "/worker/all" });
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const departmentFetch = GlobalAuthFetch({
      path: "/department/all",
    });
    const [reasonPayload, workerPayload, contactPayload, departmentPayload] =
      await Promise.all([
        fetchReason,
        fetchWorker,
        fetchContact,
        departmentFetch,
      ]);

    setDepartments(departmentPayload?.payload?.data || []);

    setReasonSelect(reasonPayload?.payload?.data?.dictionaryEntries || []);
    setWorkerSelect(workerPayload?.payload?.data || []);
    setContacts(
      contactPayload?.payload?.data
        .map((el) => ({
          uuid: el.uuid,
          label: el.name,
          branches: el.branches,
          representatives: el.representatives,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label)) || []
    );
    setLoading(false);
  };
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);
  const forMe = () => {
    form.change("to", [user]);
    form.change("departments", []);
  };
  const forEveryone = () => {
    form.change("to", workers);
    form.change("departments", []);
  };

  return (
    <div
      className="OperationLockFormStepOne"
      style={{ justifyContent: "flex-start" }}
    >
      <Row>
        <Col xs={12}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:operationLock.client")}`}
            name="client"
            data-cy="client"
            options={contacts}
            currentValues={values}
            loading={loading}
            afterIcon="add"
            afterIconSize="13px"
            afterIconClick={() => {
              const updateTableRegistry = () =>
                updateDashboardByName("contact");
              dispatch(
                show("ContactsModal", {
                  updateTableRegistry,
                  header: t("global:contacts.add"),
                  loadData,
                })
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3">
          <Field
            component={BMXSelectField}
            values={reasonSelect?.map((el) => ({
              name: el.entryName,
              value: el.uuid,
            }))}
            label={`${t("global:operationLock.reason")}*`}
            name="reason"
            data-cy="reason"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            name="description"
            label={`${t("global:operationLock.description")}`}
            component={BMXTextField}
            data-cy="description"
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <BMXSeparator title={t("global:operationLock.forTheAttentionOf")} />
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <BMXButton data-cy="alert.forMe" className="" onClick={forMe}>
            <Icon className="mx-1" fontSize={"medium"}>
              person
            </Icon>
            {t("global:alert.forMe")}
          </BMXButton>
        </Col>
        <Col md={3}>
          <BMXButton
            data-cy="alert.forEveryone"
            className=""
            onClick={forEveryone}
          >
            <Icon className="mx-1" fontSize={"medium"}>
              people
            </Icon>
            {t("global:alert.forEveryone")}
          </BMXButton>
        </Col>
        <Row className="mt-3">
          <Col md={6}>
            <Field
              component={BMXAutocomplente}
              options={workers}
              data-cy="alert.alertFor"
              currentValues={values}
              label={t("global:alert.alertFor")}
              limitTags={1}
              multiple
              name="to"
              renderOption={(props, option) => (
                <li key={option?.uuid} {...props}>
                  <BMXUserView color="primary" user={option}></BMXUserView>
                </li>
              )}
              getOptionLabel={(option) =>
                String(
                  `${option?.lastName} ${option?.firstName} ${option?.firstName} ${option?.lastName}`
                )
              }
              renderTags={(values) => {
                return values.map((option, index) => (
                  <BMXUserView
                    comma={values?.length - 1 !== index}
                    key={option?.uuid}
                    color="primary"
                    user={option}
                  ></BMXUserView>
                ));
              }}
            />
          </Col>
          <Col md={6}>
            <Field
              component={BMXAutocomplente}
              data-cy="alert.alertForDepartments"
              options={departmentsSelect}
              currentValues={values}
              label={`${t("global:alert.alertForDepartments")}`}
              limitTags={1}
              multiple
              getOptionLabel={(option) => String(`${option?.name}`)}
              renderOption={(props, option) => (
                <li key={option?.uuid} {...props}>
                  <BMXUserView
                    color="primary"
                    department={option}
                  ></BMXUserView>
                </li>
              )}
              renderTags={(values) => {
                return values.map((option, index) => (
                  <BMXUserView
                    comma={values?.length - 1 !== index}
                    key={option?.uuid}
                    color="primary"
                    department={option}
                  ></BMXUserView>
                ));
              }}
              name="departments"
            />
          </Col>
        </Row>
      </Row>
      <Row>
        <Col>
          <BMXSeparator
            miniSeparator={true}
            title={t("global:alert.alertFor")}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="summary">
            <span className="pb-2 d-block">{t("global:users")}</span>
            <div className="area">
              {values?.to &&
                Array.isArray(values?.to) &&
                values?.to.map((el) => (
                  <BMXUserView
                    className="mt-2"
                    line={"line"}
                    color="primary"
                    user={el}
                  ></BMXUserView>
                ))}
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="summary">
            <span className="pb-2 d-block">
              {t("global:departments.header")}
            </span>
            <div className="area">
              {values?.departments?.map((el) => (
                <BMXUserView
                  className="mt-2"
                  line={"line"}
                  color="secondary"
                  department={el}
                ></BMXUserView>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OperationLockFormStepOne;
