import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { useEffect, useState } from "react";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import "./LexmarkPlotFormStepOne.scss";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";

const LexmarkPlotFormStepOne = (props) => {
  const { t } = useTranslation();

  const { deviceUuid, form } = props;

  const { values } = form.getState();

  const [plotData, setPlotData] = useState(null);

  const loadData = async () => {
    const { payload } = await GlobalAuthFetch({
      path: "/device/deviceSupplies/plotData",
      method: "GET",
      addons: {
        params: {
          deviceUuid: deviceUuid,
          startDate: moment(values?.startDate).valueOf(),
          endDate: moment(values?.endDate).valueOf(),
          suppliesType: values?.suppliesType,
          timeRange: values?.timeRange?.value,
        },
      },
    });

    const data = payload?.data
      ?.map((el) => ({
        [el?.color]: el?.percentRemaining,
        date: moment(el.createdAt).format("DD-MM-YYYY"),
        createdAt: el.createdAt,
      }))
      .reduce((acc, item, idx) => {
        if (acc[idx - 1]?.date === item.date) {
          acc?.push({ ...item, ...acc[idx - 1] });
          return acc;
        }
        acc?.push(item);
        return acc;
      }, []);
    const maxKeyLength = data?.reduce((maxLength, current) => {
      const currentMaxKeyLength = Object.keys(current).length;
      return Math.max(maxLength, currentMaxKeyLength);
    }, 0);
    const objectsWithLongestKeys = data
      ?.filter((item) => {
        return Object.keys(item).length === maxKeyLength;
      })
      .toSorted(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      );

    setPlotData(
      objectsWithLongestKeys?.length > 0 ? objectsWithLongestKeys : null
    );
  };

  useEffect(() => {
    loadData();
  }, [
    values?.timeRange?.value,
    values?.startDate,
    values?.endDate,
    values?.suppliesType,
  ]);

  return (
    <div
      className="LexmarkPlotFormStepOne"
      style={{ justifyContent: "flex-start" }}
    >
      <Row>
        <Col>
          {!plotData ? (
            <div className="PlotContent">
              <CircularProgress />
            </div>
          ) : (
            <div className="PlotContent me-3">
              <LineChart width={1100} height={550} data={plotData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" padding={{ left: 30, right: 30 }} />
                <YAxis domain={[0,100]} tickFormatter={(tick) => `${tick}%`} />
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend />
                {Object.keys(plotData?.at(-1))
                  .filter((key) => key !== "date" && key !== "createdAt")
                  .sort((a, b) => a.localeCompare(b))
                  .map((key) => (
                    <Line
                      type="monotone"
                      dataKey={key}
                      stroke={key}
                      strokeWidth={4}
                      activeDot={{ r: 8 }}
                    />
                  ))}
              </LineChart>
            </div>
          )}
        </Col>
      </Row>
      <BMXSeparator title={t("global:LexmarkPlotForm.configuration")} />
      <Row>
        <Col xs={4}>
          <Field
            name="timeRange"
            values={[
              { name: t("global:LexmarkPlotForm.timeRangeDay"), value: "DAY" },
              {
                name: t("global:LexmarkPlotForm.timeRangeMonth"),
                value: "MONTH",
              },
              {
                name: t("global:LexmarkPlotForm.timeRangeYear"),
                value: "YEAR",
              },
            ]}
            component={BMXSelectField}
            label={t("global:LexmarkPlotForm.timeRange")}
          />
        </Col>
        <Col xs={4}>
          <Field
            name="startDate"
            component={BMXDatePicker}
            label={t("global:LexmarkPlotForm.startDate")}
            disableFuture
          />
        </Col>
        <Col xs={4}>
          <Field
            name="endDate"
            component={BMXDatePicker}
            label={t("global:LexmarkPlotForm.endDate")}
            disableFuture
          />
        </Col>
      </Row>
    </div>
  );
};

export default LexmarkPlotFormStepOne;
