import React, { useEffect, useState } from "react";
import "./Report.scss";
import PrintDocumentTemplate from "../../../../Components/PrintDocumentTemplate/PrintDocumentTemplate";
import moment from "moment";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import { get } from "lodash";
import slownie from "liczby-slownie";
import { currencyFormat } from "../../../../Utils/Currency/currency";
import { chunkedArray } from "../../../../Utils/Arrays/arrays";

export const Report = () => {
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState(null);
  let LP = 0;
  const headers = [
    { key: "lp", name: "Lp." },
    {
      key: "modelAndSN",
      name: (
        <div className="d-flex flex-column">
          <span>Model urządzenia</span>
          <span>Numer seryjny</span>
        </div>
      ),
    },
    {
      key: "rentAndMonitoring",
      name: (
        <div className="d-flex flex-column">
          <span>Najem</span>
          <span>Monitoring</span>
        </div>
      ),
    },
    {
      key: "countersMono",
      name: (
        <div className="d-flex flex-column">
          <span>Koszty wydruku mono</span>
          <span>Liczniki mono</span>
          <span>Nakład mono</span>
          <span>Koszt nakładu mono</span>
        </div>
      ),
    },
    {
      key: "countersColor",
      name: (
        <div className="d-flex flex-column">
          <span>Koszty wydruku kolor</span>
          <span>Liczniki kolor</span>
          <span>Nakład kolor</span>
          <span>Koszt nakładu kolor</span>
        </div>
      ),
    },
    {
      key: "countersScan",
      name: (
        <div className="d-flex flex-column">
          <span>Koszt skanów</span>
          <span>Liczniki skanów</span>
          <span>Liczba skanów</span>
          <span>Koszt nakładu skanów</span>
        </div>
      ),
    },
    {
      key: "monthCost",
      name: (
        <div className="d-flex flex-column">
          <span>Koszt miesięczny</span>
        </div>
      ),
    },
  ];

  const loadData = async () => {
    const resLogo = await GlobalAuthFetch({ path: "/settings/LOGO" });

    setLogo(resLogo?.payload?.data?.value);

    const res = await GlobalAuthFetch({ path: "/representative/all" });
    if (!res.payload) {
      return;
    }
    setUsers(
      res?.payload?.data?.map((user) => ({
        label: `${user?.name || ""} ${user?.lastname} ${user?.email}`,
        uuid: user?.email,
      }))
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const printData = JSON.parse(sessionStorage.getItem("printData"));

  const dataArray = chunkedArray(printData?.listDevicesOnReckoning, {
    chunkSize: 4,
  });

  return (
    <PrintDocumentTemplate
      fileName={`Raport ${printData?.index || "-"}`}
      usersToSendEmail={users}
    >
      {dataArray.map((table, mainIndex) => (
        <div className="TargetPrintRaportAreaFrom">
          <div className="HeaderArea">
            <div className="LogoArea">
              {Boolean(logo) ? (
                <img className="Logo" alt="Logo" src={logo} />
              ) : null}
            </div>
            <span className="DateArea">
              <span>
                Data wystawienia:{" "}
                {moment(printData?.createdAt).format("DD-MM-YYYYr")}
              </span>
            </span>
          </div>
          <div className="HeaderEnd">
            {" "}
            <span className="InnerText">RAPORT</span>
          </div>
          <div className="InfoArea">
            <div>
              <span className="Client">Klient:</span>
              <span>
                {printData?.generateFromRentContract?.contact?.name || " "}
              </span>
              <span>
                ul.{printData?.generateFromRentContract?.contact?.street || " "}
                ,{" "}
                {printData?.generateFromRentContract?.contact?.postCode || " "}{" "}
                {printData?.generateFromRentContract?.contact?.city || " "}
              </span>
              <span>
                NIP: {printData?.generateFromRentContract?.contact?.nip || " "}
              </span>
            </div>
            <div>
              <div>
                <span className="d-flex justify-content-between">
                  Numer faktury:
                  <span>{printData?.invoiceNumber}</span>
                </span>
                <span className="d-flex justify-content-between">
                  <span>Numer umowy</span>
                  <span>
                    {printData?.generateFromRentContract?.contractNo || " "}
                  </span>
                </span>
                <span className="d-flex justify-content-between">
                  <span>ID rozliczenia</span>
                  <span>{printData?.index || " "}</span>
                </span>
                <span className="d-flex justify-content-between">
                  <span>Data rozliczenia</span>
                  <span>
                    {moment(printData?.invoiceDateField).format("DD-MM-YYYYr")}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="TablePrintArea">
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={`Header-${index}`}>{header.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {[
                  ...table.map((el) => ({
                    modelAndSN: (
                      <div className="d-flex flex-column">
                        <span>
                          {el?.device?.printerModel?.manufacturer?.entryName ||
                            ""}{" "}
                          {el?.device?.printerModel?.modelName || ""}
                        </span>
                        <span>{el?.device?.serialNumber || ""}</span>
                      </div>
                    ),
                    rentAndMonitoring: (
                      <div className="d-flex flex-column">
                        <span>{currencyFormat(el?.rentalCost)}</span>
                        <span>{currencyFormat(el?.monitoringCost)}</span>
                      </div>
                    ),
                    countersMono: (
                      <div className="d-flex flex-column">
                        <span>
                          {currencyFormat(el?.monoSingleCost, { fixed: 3 })}
                        </span>
                        <span>
                          {el?.beforeCounterMono?.toFixed(0) || "0"} -{" "}
                          {el?.currentCounterMono?.toFixed(0) || "0"}
                        </span>
                        <span>{el?.monthlyMono?.toFixed(0) || "0"}</span>
                        <span>{currencyFormat(el?.monthlyMonoCost)}</span>
                      </div>
                    ),
                    countersColor: (
                      <div className="d-flex flex-column">
                        <span>
                          {currencyFormat(el?.colorSingleCost, { fixed: 3 })}
                        </span>
                        <span>
                          {el?.beforeCounterColor?.toFixed(0) || "0"} -{" "}
                          {el?.currentCounterColor?.toFixed(0) || "0"}
                        </span>
                        <span>{el?.monthlyColor?.toFixed(0) || "0"}</span>
                        <span>{currencyFormat(el?.monthlyColorCost)}</span>
                      </div>
                    ),
                    countersScan: (
                      <div className="d-flex flex-column">
                        <span>
                          {currencyFormat(el?.scanSingleCost, { fixed: 3 })}
                        </span>
                        <span>
                          {el?.beforeCounterScan?.toFixed(0) || "0"} -{" "}
                          {el?.currentCounterScan?.toFixed(0) || "0"}
                        </span>
                        <span>{el?.monthlyScan?.toFixed(0) || "0"}</span>
                        <span>{currencyFormat(el?.monthlyScanCost)}</span>
                      </div>
                    ),
                    monthCost: (
                      <div className="d-flex flex-column">
                        <span>{currencyFormat(el?.fullCosts)}</span>
                      </div>
                    ),
                    ...el,
                  })),
                ]?.map((data) => {
                  LP += 1;
                  return (
                    <tr key={`dataHeader-${LP}`}>
                      {headers.map((header, idx) => (
                        <td key={`data-${idx}`}>
                          {header.key === "lp"
                            ? LP
                            : get(data, header.key, "-")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {mainIndex === dataArray.length - 1 ? (
              <div className="TableFooter">
                <span>Dziękujemy za korzystanie z naszych usług.</span>
                <b>
                  Razem netto:{" "}
                  <b className="ms-4">{currencyFormat(printData?.monthCost)}</b>
                </b>
              </div>
            ) : null}
          </div>
          {mainIndex === dataArray.length - 1 ? (
            <div
              className="SummaryArea"
              word-summary={`${slownie(
                printData?.monthCost.toFixed(2).split(".")[0]
              )} złotych i ${slownie(
                printData?.monthCost.toFixed(2).split(".")[1] === "00" ||
                  !printData?.monthCost.toFixed(2).split(".")[1]
                  ? 0
                  : printData?.monthCost.toFixed(2).split(".")[1]
              )} groszy`}
            >
              <span>Razem brutto do zapłaty:</span>
              <span>{currencyFormat(printData?.monthCost)}</span>
            </div>
          ) : null}
          <div className="Footer">www.biuromax-balcer.pl</div>
        </div>
      ))}
    </PrintDocumentTemplate>
  );
};
