import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
import { updateDashboardByName } from "../../../../../Utils/Sockets";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXDatePicker from "../../../../../Components/Inputs/BMXDatePicker/BMXDatePicker";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXAutocomplente from "../../../../../Components/Inputs/BMXAutocomplete/BMXAutocomplete";
import BMXUserView from "./../../../../../Components/BMXUserView/BMXUserView";
import { loadPictures } from "../../../../../Utils/Workers/WorkersUtils";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
const RentContractFormStepOne = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { form, values, initialValues, view } = props;

  const [contacts, setContacts] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const [users, setUsers] = useState([]);

  const loadData = async () => {
    const fetchContact = GlobalAuthFetch({ path: "/contact/all" });
    const fetchUsers = GlobalAuthFetch({ path: "/worker/all" });
    const [resContact, resUsers] = await Promise.all([
      fetchContact,
      fetchUsers,
    ]);
    const workers = resUsers?.payload?.data.sort((a, b) =>
      a.lastName.localeCompare(b.lastName)
    );
    const newWorkers = [];
    for (const el of workers) {
      newWorkers.push({
        uuid: el.uuid,
        label: `${el.lastName} ${el.firstName}`,
        lastName: el.lastName,
        firstName: el.firstName,
        profileImage: await loadPictures(el),
      });
    }
    setUsers(newWorkers);

    setContacts(
      resContact?.payload?.data
        .map((el) => ({
          uuid: el.uuid,
          label: el.name,
          branches: el.branches,
          representatives: el.representatives,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label))
    );
  };
  const loadRepresentative = (contact) => {
    const selectedRepresentatives = contact?.representatives?.map(
      (representative) => ({
        uuid: representative.uuid,
        label: `${representative.name || ""} ${representative.lastname || ""}`,
      })
    );
    if (!selectedRepresentatives) {
      setRepresentatives([]);
      return;
    }
    setRepresentatives(
      selectedRepresentatives?.sort((a, b) => a.label.localeCompare(b.label))
    );
    form.change(
      "representative",
      selectedRepresentatives.find(
        (el) => el.uuid === values?.representative?.uuid
      ) || null
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      const rentContractTypeFetch = GlobalAuthFetch({
        path: "/dictionary/rentContractType/fieldName",
      });
      const [rentContractTypePayload] = await Promise.all([
        rentContractTypeFetch,
      ]);

      setRentContractTypeSelect(
        rentContractTypePayload?.payload?.data?.dictionaryEntries
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  const [rentContractTypeSelect, setRentContractTypeSelect] = useState([]);
  useEffect(() => {
    const selectedContact = contacts.find(
      (el) => el.uuid === values?.contact?.uuid
    );
    loadRepresentative(selectedContact);

    // eslint-disable-next-line
  }, [values.contact?.uuid, contacts]);

  useEffect(() => {
    if (values?.contact?.uuid === initialValues?.contact?.uuid) return;
    form.change("representative", null);
    // eslint-disable-next-line
  }, [values.contact?.uuid]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:rentContract.information")} />
      <Row>
        <Col xs={{ span: 5 }}>
          <Field
            disabled={view}
            name="title"
            label={`${t("global:rentContract.title")}`}
            component={BMXTextField}
            data-cy="title"
          />
        </Col>

        <Col xs={{ span: 3, offset: 1 }}>
          <Field
            disabled={view}
            name="startDate"
            data-cy="startDate"
            component={BMXDatePicker}
            label={t("global:rentContract.startDate")}
          />
        </Col>
        <Col xs={3}>
          <Field
            disabled={view}
            name="endDate"
            data-cy="endDate"
            component={BMXDatePicker}
            label={t("global:rentContract.endDate")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3 }}>
          <Field
            name="contractNo"
            label={`${t("global:rentContract.contractNo")}`}
            component={BMXTextField}
            data-cy="contractNo"
            disabled={true}
          />
        </Col>
        <Col xs={{ span: 3 }}>
          <Field
            name="foreignContractNo"
            label={`${t("global:rentContract.foreignContractNo")}`}
            component={BMXTextField}
            data-cy="foreignContractNo"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Field
            disabled={view}
            component={BMXAutocomplente}
            label={`${t("global:rentContract.contact")}`}
            name="contact"
            data-cy="contact"
            options={contacts}
            currentValues={values}
            afterIcon="add"
            afterIconSize="13px"
            afterIconClick={() => {
              const updateTableRegistry = () =>
                updateDashboardByName("contact");
              dispatch(
                show("ContactsModal", {
                  updateTableRegistry,
                  header: t("global:contacts.add"),
                  loadData,
                })
              );
            }}
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={view}
            fieldtype="checkbox"
            component={BMXTextField}
            name="buyer"
            label={t("global:rentContract.buyer")}
          />
        </Col>
      </Row>
      {values.buyer ? (
        <Row>
          <Col xs={8}>
            <Field
              disabled={view}
              component={BMXAutocomplente}
              label={`${t("global:rentContract.contactBuyer")}`}
              name="contactBuyer"
              data-cy="contactBuyer"
              options={contacts}
              currentValues={values}
              afterIcon="add"
              afterIconSize="13px"
              afterIconClick={() => {
                const updateTableRegistry = () =>
                  updateDashboardByName("contact");
                dispatch(
                  show("ContactsModal", {
                    updateTableRegistry,
                    header: t("global:contacts.add"),
                    loadData,
                  })
                );
              }}
            />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col xs={12}>
          <Field
            component={BMXAutocomplente}
            label={`${t("global:rentContract.representative")}`}
            name="representative"
            data-cy="representative"
            options={representatives}
            disabled={!values.contact || view}
            currentValues={values}
            afterIcon="add"
            afterIconSize="13px"
            afterIconClick={() => {
              const contactUuid =
                values?.contact?.uuid || values?.contact?.value;
              const updateSingleRegistry = () => {
                updateDashboardByName("contact", contactUuid);
              };
              dispatch(
                show("RepresentativesModal", {
                  loadData,
                  header: t("global:contact.representativeAdd"),
                  updateSingleRegistry,
                  uuid: contactUuid,
                })
              );
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 6 }}>
          <Field
            disabled={view}
            component={BMXSelectField}
            values={rentContractTypeSelect
              ?.sort((a, b) => a.entryName.localeCompare(b.entryName))
              ?.map((el) => ({
                name: el.entryName,
                value: el.uuid,
              }))}
            label={`${t("global:rentContract.rentContractType")}`}
            name="rentContractType"
            data-cy="rentContractType"
          />
        </Col>
        <Col xs={{ span: 6 }}>
          <Field
            disabled={view}
            component={BMXAutocomplente}
            label={`${t("global:rentContract.customerAssistant")}`}
            name="customerAssistant"
            data-cy="customerAssistant"
            options={users}
            currentValues={values}
            renderOption={(props, option) => (
              <li key={option.uuid} {...props}>
                <BMXUserView color="primary" user={option}></BMXUserView>
              </li>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractFormStepOne;
