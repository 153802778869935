import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import BMXSeparator from "../../../../../Components/Separator/BMXSeparator";
import { Col, Row } from "react-bootstrap";
import { Icon, InputAdornment, Tooltip } from "@mui/material";
import { Field } from "react-final-form";
import BMXTextField from "../../../../../Components/Inputs/BMXTextField/BMXTextField";
import BMXAsynchronous from "../../../../../Components/Inputs/BMXAsynchronous/BMXAsynchronous";
import { GlobalAuthFetch } from "../../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
import BMXSelectField from "../../../../../Components/Inputs/BMXSelectField/BMXSelectField";
const RentContractDeviceFormStepOne = (props) => {
  const { t } = useTranslation();

  const { values, form, update, view } = props;
  const [options, setOptions] = useState([]);

  const { values: formValues } = form.getState();

  const getData = async (searchTerm) => {
    const data = GlobalAuthFetch({
      path: `/device/findBySN/${searchTerm.toUpperCase()}`,
    });

    const [drawingListPayload] = await Promise.all([data]);
    const drawingList = drawingListPayload?.payload?.data?.map((p) => {
      return {
        uuid: p.uuid,
        value: `${p.serialNumber}`,
        ...p,
      };
    });
    if (drawingList?.length > 0) {
      setOptions(drawingList);
    } else {
      setOptions([]);
    }
  };
  useEffect(() => {
    const subscriptionCost =
      Number(values?.rentalCost) +
      Number(values?.monitoringCost) +
      Number(values?.monoPackageCost) +
      Number(values?.colorPackageCost);
    if (!Number.isNaN(subscriptionCost)) {
      form.change("subscription", subscriptionCost);
    }

    // eslint-disable-next-line
  }, [
    values.rentalCost,
    values.monitoringCost,
    values.monoPackageCost,
    values.colorPackageCost,
  ]);

  useEffect(() => {
    if (values?.contractType?.value === "FLEXIBLE") {
      form.change("monoPackageCost", 0);
      form.change("colorPackageCost", 0);
      form.change("monoPackageSize", 0);
      form.change("colorPackageSize", 0);
    }
    // eslint-disable-next-line
  }, [values.contractType]);
  return (
    <div style={{ justifyContent: "flex-start" }}>
      <BMXSeparator title={t("global:RentContractDevice.defaultInformation")} />
      <Row>
        <Col xs={{ span: 4 }}>
          <Field
            currentValues={values}
            className="sn"
            component={BMXAsynchronous}
            label={`${t("global:RentContractDevice.sn")}`}
            name="sn"
            options={options}
            disabled={update}
            getData={getData}
            data-cy="sn"
            disablePortal={true}
            onClose={() => {
              const { values } = form.getState();

              form.change(
                "producent",
                values?.sn?.printerModel?.manufacturer?.entryName
              );
              form.change("model", values?.sn?.printerModel?.modelName);
              form.change(
                "producerAndModel",
                `${values?.sn?.printerModel?.manufacturer?.entryName || ""} ${
                  values?.sn?.printerModel?.modelName || ""
                }`
              );
              form.change(
                "monoCounterStart",
                values?.sn?.monoSideCount ? values?.sn?.monoSideCount : ""
              );
              form.change(
                "colorCounterStart",
                values?.sn?.colorSideCount ? values?.sn?.colorSideCount : ""
              );
              form.change(
                "scanCounterStart",
                values?.sn?.copySideCount ? values?.sn?.copySideCount : ""
              );
              form.change(
                "deviceOwner",
                `${values?.sn?.deviceOwner?.entryName || ""} `
              );
            }}
          />
        </Col>
        <Col xs={4}>
          <Field
            disabled={view}
            data-cy="contractType"
            name="contractType"
            component={BMXSelectField}
            values={[
              {
                name: t("global:RentContractDevice.subscription"),
                value: "SUBSCRIPTION",
              },
              {
                name: t("global:RentContractDevice.elastic"),
                value: "FLEXIBLE",
              },
            ]}
            label={`${t("global:RentContractDevice.contractType")}`}
          />
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            disabled={view}
            name="rentalCost"
            label={`${t("global:RentContractDevice.rentalCost")}`}
            component={BMXTextField}
            type="money"
            decimalScale={2}
            data-cy="rentalCost"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 4 }}>
          <Field
            disabled={view}
            name="producerAndModel"
            readOnly
            label={`${t("global:RentContractDevice.producerAndModel")}`}
            component={BMXTextField}
            data-cy="producerAndModel"
          />
        </Col>

        <Col xs={{ span: 4 }}>
          {formValues?.contractType?.value === "SUBSCRIPTION" ? (
            <Field
              disabled={true}
              name="subscription"
              label={`${t("global:rentContract.subscription")}`}
              type="money"
              decimalScale={2}
              component={BMXTextField}
              data-cy="subscription"
            />
          ) : null}
        </Col>
        <Col xs={{ span: 4 }}>
          <Field
            disabled={view}
            name="monitoringCost"
            label={`${t("global:RentContractDevice.monitoringCost")}`}
            component={BMXTextField}
            type="money"
            decimalScale={2}
            data-cy="monitoringCost"
          />
        </Col>
      </Row>
      <Row
        className="p-2 "
        style={{ backgroundColor: "var(--BMX-details-grey)" }}
      >
        <Row>
          <Col>{t("global:RentContractDevice.monoPrinting")}</Col>
        </Row>
        <Row>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view}
              name="billedMono"
              className="pt-2"
              data-cy="active"
              fieldtype="checkbox"
              component={BMXTextField}
              label={t("global:RentContractDevice.settled")}
            ></Field>
          </Col>
          <Col xs={{ span: 3 }}>
            <Field
              disabled={view || !values.billedMono}
              name="monoSingleCost"
              label={`${t("global:rentContract.monoSingleCost")}`}
              type="money"
              decimalScale={3}
              component={BMXTextField}
              data-cy="monoSingleCost"
            />
          </Col>
          <Col xs={{ span: 2 }}>
            {formValues?.contractType?.value === "SUBSCRIPTION" ? (
              <Field
                disabled={view || !values.billedMono}
                name="monoPackageSize"
                label={`${t("global:RentContractDevice.monoPackageSize")}`}
                component={BMXTextField}
                nonDecimale
                type="number"
                data-cy="monoPackageSize"
              />
            ) : null}
          </Col>

          <Col xs={{ span: 3 }}>
            {formValues?.contractType?.value === "SUBSCRIPTION" ? (
              <Field
                disabled={view || !values.billedMono}
                name="monoPackageCost"
                label={`${t("global:rentContract.monoPackageCost")}`}
                type="money"
                decimalScale={2}
                component={BMXTextField}
                data-cy="monoPackageCost"
              />
            ) : null}
          </Col>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view || !values.billedMono}
              name="monoCounterStart"
              label={`${t("global:RentContractDevice.monoCounterStart")}`}
              component={BMXTextField}
              type="number"
              nonDecimale
              data-cy="monoCounterStart"
              InputProps={{
                endAdornment: Boolean(formValues?.sn?.monoSideCount) ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t("global:RentContractDevice.fetchedFromApi")}
                      placement="top"
                    >
                      <Icon
                        className="primary text"
                        style={{ fontSize: "1em" }}
                      >
                        cloud_done
                      </Icon>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Col>
        </Row>
      </Row>
      <Row
        className="p-2 my-2"
        style={{ backgroundColor: "var(--BMX-diarrhea-color)" }}
      >
        <Row>
          <Col>{t("global:RentContractDevice.colorPrinting")}</Col>
        </Row>
        <Row>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view}
              name="billedColor"
              className="pt-2"
              data-cy="active"
              fieldtype="checkbox"
              component={BMXTextField}
              label={t("global:RentContractDevice.settled")}
            ></Field>
          </Col>
          <Col xs={{ span: 3 }}>
            <Field
              disabled={view || !values.billedColor}
              name="colorSingleCost"
              label={`${t("global:RentContractDevice.colorSingleCost")}`}
              component={BMXTextField}
              type="money"
              decimalScale={3}
              data-cy="colorSingleCost"
            />
          </Col>
          <Col xs={{ span: 2 }}>
            {formValues?.contractType?.value === "SUBSCRIPTION" ? (
              <Field
                disabled={view || !values.billedColor}
                name="colorPackageSize"
                label={`${t("global:RentContractDevice.colorPackageSize")}`}
                component={BMXTextField}
                nonDecimale
                type="number"
                data-cy="colorPackageSize"
              />
            ) : null}
          </Col>
          <Col xs={{ span: 3 }}>
            {formValues?.contractType?.value === "SUBSCRIPTION" ? (
              <Field
                disabled={view || !values.billedColor}
                name="colorPackageCost"
                label={`${t("global:rentContract.colorPackageCost")}`}
                type="money"
                decimalScale={2}
                component={BMXTextField}
                data-cy="colorPackageCost"
              />
            ) : null}
          </Col>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view || !values.billedColor}
              name="colorCounterStart"
              label={`${t("global:RentContractDevice.colorCounterStart")}`}
              component={BMXTextField}
              nonDecimale
              type="number"
              data-cy="colorCounterStart"
              InputProps={{
                endAdornment: Boolean(formValues?.sn?.colorSideCount) ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t("global:RentContractDevice.fetchedFromApi")}
                      placement="top"
                    >
                      <Icon
                        className="primary text"
                        style={{ fontSize: "1em" }}
                      >
                        cloud_done
                      </Icon>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Col>
        </Row>
      </Row>
      <Row
        className="p-2 "
        style={{ backgroundColor: "var(--BMX-details-grey)" }}
      >
        <Row>
          <Col>{t("global:RentContractDevice.scan")}</Col>
        </Row>
        <Row>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view}
              name="billedScan"
              className="pt-2"
              data-cy="active"
              fieldtype="checkbox"
              component={BMXTextField}
              label={t("global:RentContractDevice.settled")}
            ></Field>
          </Col>
          <Col xs={{ span: 3 }}>
            <Field
              disabled={view || !values.billedScan}
              name="scanSingleCost"
              label={`${t("global:rentContract.scanSingleCost")}`}
              type="money"
              decimalScale={3}
              component={BMXTextField}
              data-cy="scanSingleCost"
            />
          </Col>
          <Col xs={{ span: 2 }}>
            <Field
              disabled={view || !values.billedScan}
              name="scanCounterStart"
              label={`${t("global:RentContractDevice.scanCounterStart")}`}
              component={BMXTextField}
              nonDecimale
              type="number"
              data-cy="scanCounterStart"
              InputProps={{
                endAdornment: Boolean(formValues?.sn?.copySideCount) ? (
                  <InputAdornment position="end">
                    <Tooltip
                      title={t("global:RentContractDevice.fetchedFromApi")}
                      placement="top"
                    >
                      <Icon
                        className="primary text"
                        style={{ fontSize: "1em" }}
                      >
                        cloud_done
                      </Icon>
                    </Tooltip>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Col>
        </Row>
      </Row>
      <Row className="mt-2">
        <Col xs={4}>
          <Field
            name="deviceOwner"
            label={`${t("global:RentContractDevice.deviceOwner")}`}
            component={BMXTextField}
            disabled={true}
            data-cy="deviceOwner"
          />
        </Col>
      </Row>
    </div>
  );
};

export default RentContractDeviceFormStepOne;
